<template>
    <div class="sld_settle_agreement">
        <div class="sld_agreement">
            <p class="agreement_tit">签订入驻协议</p>
            <div class="sld_agreement_box">
                <h4>{{ agreeContent.title }}</h4>
                <p v-html="agreeContent.content" style="margin-top: 10px"></p>
                <!-- <p>使用本公司服务所遵守的条款和条件。</p>
        <p>一、用户资格</p>
        <p>本公司的服务仅向使用法律下能够签订具有法律约束力的合同的个人提供并仅由其使用。在不限制前述规定的前提下,本公司的服务不向18周岁以下或</p> -->
            </div>
            <div class="agreement_check">
                <el-checkbox v-model="checked" label="我已经阅读并同意以上协议" size="large" />
            </div>
            <div class="agreement_btn" @click="nextStep">下一步</div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import SldCommonEmpty from "@/components/SldCommonEmpty";
export default {
    name: "AccountList",
    components: { SldCommonEmpty },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const checked = ref(false); //同意
        const agreeContent = reactive({
            content: "",
            title: ""
        });
        const nextStep = () => {
            if (checked.value) {
                router.push({
                    path: "/member/settle/enterprise"
                });
            } else {
                ElMessage("请同意商户入驻协议");
            }
        };

        const agreementCode = reactive({
            agreementCode: "business_residence_agreement"
        });
        // 入驻协议数据
        const getInitData = () => {
            proxy.$get("api/shopp/agreement/one", agreementCode).then((res) => {
                //旧/v3/system/front/agreement/detail
                if (res.code == 200) {
                    res.data.map((item) => {
                        if (item.agreementCode == agreementCode.agreementCode) {
                            agreeContent.content = proxy
                                .$quillEscapeToHtml(res.data.content)
                                .replace(
                                    reg,
                                    '<img style="width:100%;height:auto;margin:0 auto;border-top:36rpx solid transparent;"'
                                );
                            agreeContent.title = item.title;
                        }
                    });
                }
            });
        };

        //企业会员入住供应商前期准备 初始化

        // const getRegister = ()=>{
        //   proxy.$post('v3/member/front/member/register').then(res => {

        //     })
        // }
        const agrType = ref("");
        //获取入驻详情接口
        const getDetail = () => {
            proxy.$get("v3/member/front/member/applyDetail").then((res) => {
                if (res.state == 200) {
                    console.log(agrType.value);
                    if (
                        (res.data.state == 1 || res.data.state == 2 || res.data.state == 3 || res.data.state == 4) &&
                        agrType.value != 2
                    ) {
                        router.push({
                            path: "/member/settle/results"
                        });
                    }
                }
            });
        };
        onMounted(() => {
            agrType.value = route.query.type ? route.query.type : "";
            getInitData();
            // getRegister()
            getDetail();
        });

        return {
            L,
            checked,
            nextStep,
            getInitData,
            agreeContent
        };
    }
};
</script>

<style lang="scss" scoped>
@import "../../../style/member/agreement.scss";
</style>
<style lang="scss">
.sld_settle_agreement {
    .el-checkbox {
        display: flex;
        align-items: center;
    }
    .el-checkbox__inner {
        border-radius: 50%;
        border-color: rgb(96, 98, 102);
    }
    .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: rgb(96, 98, 102);
    }
    .agreement_check {
        .el-checkbox__input.is-checked {
            .el-checkbox__inner {
                background: $colorMain;
                border: 1px solid $colorMain;
                border-color: $colorMain;
                border-radius: 50%;
            }
        }
        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: $colorMain;
        }
    }
}
</style>
